import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function elterncoaching() {
  return (
    <>
      <Metadata title='Elterncoaching' />
      <Section heading='Systemisches Elterncoaching'>
        <p>
          Dies ist ein Beratungsformat für Eltern, welches sich mit den
          alltäglichen Machtkämpfen rund um schwieriges kindliches Verhalten
          auseinandersetzt. Es werden u.a. Möglichkeiten gewaltfreier
          Beharrlichkeit in der Erziehung und ein gewinnender Ausdruck von
          wachsamer Sorge durch Beziehungsgesten vermittelt. Mit dem erlernten
          Vorgehen gewinnen Eltern an Autorität und modellieren zugleich
          Selbstkontrolle im Verhalten als Vorbild für das Kind.
        </p>
      </Section>
    </>
  );
}

export default elterncoaching;
